// import { Cubic, Quint, Expo } from "gsap/gsap-core";
import { Power2, Power4, Expo } from "gsap/TweenMax";

export default class Helpers {
	static ANIMATION_SETTINGS = {
		// Animation settings (after the drag ends, the menu, letters and images need to be positioned or reset)
		menu: { duration: 0.8, ease: Power2.easeOut },
		letters: { duration: 0.8, ease: Power2.easeOut },
		images: { duration: 1, ease: Power4.easeOut },
		// Grid
		grid: { duration: 0.8, ease: Expo.easeOut },
		// Hiding the letters to show the images grid
		allMenuLettersToggle: { duration: 0.8, ease: Expo.easeOut },
		// Explore link
		explore: { duration: 0.6, ease: Expo.easeOut },
		// backToMenuCtrl
		backCtrl: { duration: 0.6, ease: Expo.easeOut },
		// Cursor arrows
		cursor: { duration: 1, ease: Expo.easeOut },
	}

	// Gets Window size
	static winSize = {
		width: 0,
		height: 0
	};
	static getWinsize = () => {
		if (typeof window !== `undefined`) {
			this.winSize = {
				width: window.innerWidth,
				height: window.innerHeight
			};
		}
		// console.log("Helpers :: getWinsize");
	}

	// Gets the mouse position
	static mousePos;
	static getMousePos = (e) => {
		let posX = 0;
		let posY = 0;
		if (!e) e = window.event;
		if (typeof window !== `undefined`) {
			if (e.type === "touchstart") {
				var touch = e.touches[0] || e.changedTouches[0];
				posX = touch.screenX;
				posY = touch.screenY;
			} else {
				if (e.pageX || e.pageY) {
					posX = e.pageX;
					posY = e.pageY;
				} else if (e.clientX || e.clientY) {
					posX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
					posY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
				}
			}
			this.mousePos = { x: posX, y: posY }
		};
		// console.log(this.mousePos);
		return this.mousePos;
	}
}

export class MathUtils {
	static lineEq = (y2, y1, x2, x1, currentVal) => {
		// y = mx + b 
		var m = (y2 - y1) / (x2 - x1);
		var b = y1 - m * x1;
		return m * currentVal + b;
	}

	static lerp = (a, b, n) => (1 - n) * a + n * b;

	static getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

	static getRandomInt = (min, max) => {
		return (Math.random() * (max - min) + min).toFixed(0);
	}
};

/*
const helpers = {
	helperObject: {
		xyz: "Hello 123"
	},
	helperMethod: function () {
		let name = "test123"
		return name;
	},
}
export default helpers;
*/