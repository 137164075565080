import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "../css/main.css"
import { Container } from 'react-bootstrap'
import "../css/header.css"

class NonAnimatedHeader extends Component {
  render() {
    let mainLogo;
    if (this.props.logoType === "light-yellow-bg" || this.props.logoType === "dark-yellow-bg" || this.props.logoType === "light-pink-bg") {
      mainLogo = <img src="/images/fulltoplogo-blue.png" alt="top logo" />
    } else if (this.props.logoType === "light-blue-bg" || this.props.logoType === "dark-blue-bg") {
      mainLogo = <img src="/images/fulltoplogo-yellow.png" alt="top logo" />
    } else {
      mainLogo = <img src="/images/fulltoplogo.png" alt="top logo" />
    }
    return (
      <header id="site-header">
        <Container>
          <div className="header-logo">
            <Link
              to="/" className="anim-completed"
            >
              <span className="header-logo-top">
                {mainLogo}
              </span>
            </Link>
          </div>
        </Container>
      </header>
    )
  }
}

NonAnimatedHeader.propTypes = {
  siteTitle: PropTypes.string,
}

NonAnimatedHeader.defaultProps = {
  siteTitle: ``,
}

export default NonAnimatedHeader
