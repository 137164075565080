import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Container } from 'react-bootstrap'
import "../css/header.css"
import "../css/main.css"
import { TimelineLite } from "gsap/all"
// import TopLogo from "../images/logo-top.inline.svg"
import $ from "jquery"
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Helpers from "./../classes/helpers";

const { detect } = require('detect-browser');
const browser = detect();

const HEADER_QUERY = gql`
  {  
      frontlineSettings {
        frontline_general_options {
          logoText1
          logoText2
          websiteLogo
          googleAnalyticsCode
        }
      }
  }
`;

const Header = () => {
  const { loading, error, data } = useQuery(HEADER_QUERY);

  useEffect(() => {
    if (loading === false) {

      const winHgt = Helpers.winSize.height;
      const logoHgt = $('.header-logo').outerHeight();
      // const logoTextOffset = 14;
      // const decadeTxtHgt = $('.header-logo-decade-txt').outerHeight();
      const logoPos = winHgt / 2 - logoHgt * 1.5;

      $("body").addClass(browser.name);
      $("body").addClass(browser.os.toLowerCase());

      let logoTl = new TimelineLite({ paused: true });

      logoTl
        .fromTo(".animatedHeader .header-logo", 1, { y: logoPos, autoAlpha: 0 }, { y: logoPos, autoAlpha: 1 }, 0.2)
        .fromTo(".animatedHeader .header-logo-decade-txt", 1, { x: 300, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")

      if (window.matchMedia("(min-width: 576px)").matches) {

        // if ($("body").hasClass("mac") && browser.name === "chrome") {

        // Condition Mac Chrome if any issue

        // } else {
        // logo & globe load animation
        if (window.matchMedia("(min-width: 1501px)").matches) {
          logoTl.fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, y: -40, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")
        } else if (window.matchMedia("(min-width: 992px)").matches) {
          logoTl.fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, y: -26, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")
        } else if (window.matchMedia("(min-width: 768px)").matches) {
          logoTl.fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, y: -20, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")
        } else {
          logoTl.fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, y: -14, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")
        }

        logoTl
          .fromTo(".animatedHeader .home-globe-bg", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, "-=0.4")
          .fromTo(".animatedHeader .header-logo", 1, { y: logoPos }, { y: 0 }, "-=0.4")
          .add("loadInitial")
          .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "loadInitial-=0.1")

        // logo small animation
        // if (browser.name === "safari") {

        // Condition for safari if any issue

        // } else {
        if (window.matchMedia("(min-width: 1501px)").matches) {
          logoTl
            .fromTo(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 148 }, { fontSize: 24 }, "loadInitial")
            .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 148 }, { fontSize: 24 }, "loadInitial");
        } else if (window.matchMedia("(min-width: 992px)").matches) {
          logoTl
            .fromTo(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 90 }, { fontSize: 24 }, "loadInitial")
            .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 90 }, { fontSize: 24 }, "loadInitial");
        } else if (window.matchMedia("(min-width: 768px)").matches) {
          logoTl
            .fromTo(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 68 }, { fontSize: 24 }, "loadInitial")
            .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 68 }, { fontSize: 24 }, "loadInitial");
        } else {
          logoTl
            .fromTo(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 48 }, { fontSize: 24 }, "loadInitial")
            .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 48 }, { fontSize: 24 }, "loadInitial");
        }
        // }
        // }

      } else {
        logoTl
          .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, y: -6, autoAlpha: 0 }, { x: 0, autoAlpha: 1 }, "-=0.5")
          .fromTo(".animatedHeader .home-globe-bg", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, "-=0.4")
          .fromTo(".animatedHeader .header-logo", 1, { y: logoPos }, { y: 0 }, "-=0.4")
          .add("loadInitial")
          // logo small animation
          .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "loadInitial-=0.1")
          .fromTo(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 26 }, { fontSize: 20 }, "loadInitial")
          .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 26 }, { fontSize: 20 }, "loadInitial")
      }

      logoTl
        .to(".animatedHeader .header-logo-frontline-txt", 1, { y: 0 }, "loadInitial")
        .set("#site-header .header-logo > a", { className: "+=anim-completed" })
        .add("loadHeader")

      // Content load animation
      logoTl
        .fromTo(".animatedHeader .menu__item", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, "loadHeader-=0")
        .staggerFromTo(".animatedHeader .grid__item-wrap", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.2, "loadHeader-=1.5")
        .fromTo(".animatedHeader .nav-menu .nav-control", 0.5, { autoAlpha: 0 }, { autoAlpha: 1 });

      // .set(".animatedHeader .slide-article-img", { className: "+=imgLoaded" })
      // .staggerTo(".animatedHeader .slide-article-img-cover", 1, { width: "100%" }, 0.1, "-=2")

      logoTl
        .play();

    }

  }, [loading]);

  if (loading) return null;
  if (error) return <p>ERROR: {error.message}</p>;

  return (
    <header id="site-header">
      <div dangerouslySetInnerHTML={{ __html: data.frontlineSettings.frontline_general_options.googleAnalyticsCode }} />
      <Container>
        <div className="header-logo">
          <Link
            to="/"
          >
            <span className="header-logo-top">
              <div dangerouslySetInnerHTML={{ __html: data.frontlineSettings.frontline_general_options.websiteLogo }} />
            </span>
            <span className="header-logo-decade-txt">{data.frontlineSettings.frontline_general_options.logoText1}</span>
            <span className="header-logo-frontline-txt">{data.frontlineSettings.frontline_general_options.logoText2}</span>
          </Link>
        </div>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
