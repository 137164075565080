/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import NonAnimatedHeader from "./nonAnimatedHeader"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/main.css"

const Layout = ({ animatedHeader, children, logoType }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={animatedHeader}>
      {animatedHeader ? <Header siteTitle={data.site.siteMetadata.title} /> : <NonAnimatedHeader logoType={logoType} siteTitle={data.site.siteMetadata.title} />}
      <main>{children}</main>
      <footer></footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
